@import "styles";

.new-drawer-page {
    form {
        background-color: white;
        width: 50%;
        min-width: 34rem;
        border-radius: 1rem;
        padding: 2rem 2rem 3rem;

        @include mobile {
            width: 100%;
            min-width: 100%;
        }
    }

    h1 {
        font-size: 2rem;
        color: $gray-800;
        margin-bottom: 1rem;

        @include mobile {
            margin-bottom: 1rem;
        }
    }

    label {
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
        color: $gray-700;
        font-weight: bold;

        @include mobile {
            font-size: 1rem;
        }
    }

    form {
        > div {
            display: flex;
            flex-direction: column;
        }

        > div + div {
            margin-top: 1rem;
        }
    }

    input[type="text"],
    textarea {
        width: 90%;
        border: 2px solid $gray-300;
        border-radius: 0.3rem;
        color: $gray-800;
        font-size: 1.1rem;
        padding: 7px;

        &:focus {
            border-color: $amber-800;
        }

        &::placeholder {
            color: $gray-400;
            font-size: 1rem;
        }

        @include mobile {
            width: 100%;
        }
    }

    textarea {
        height: 5rem;
    }

    p {
        font-size: 0.7rem;
        color: $gray-500;
        font-weight: bold;
        line-height: 1.5;

        @include mobile {
            font-size: 0.7rem;
            margin-top: 0;
            line-height: 1.5;
        }
    }

    .desc-length {
        margin-left: 5px;
        font-size: 0.7rem;
    }

    .row-item {
        flex-direction: row;
        align-items: center;

        label {
            margin-bottom: 0;
            margin-right: 1rem;
        }
    }
    .btn-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .check-box-item {
        display: flex;
        align-items: center;
    }

    #drawer-all-public {
        margin-right: 0.5rem;
    }
}
