@import "styles";

.best-card-component {
    background-color: rgba($color: $gray-200, $alpha: 0.7);
    width: 20rem;
    height: 10rem;
    border-radius: 10px;
    position: relative;
    padding: 0.7rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    border: 0.15rem solid $gray-200;

    @include mobile {
        width: 100%;
        margin: 0.5rem 0;
    }

    &:hover {
        border: 0.15rem solid $gray-400;
    }

    .drawer-title {
        margin-top: 0.3rem;
        font-size: 1rem;
        color: $gray-800;
        font-weight: bolder;
    }

    .drawer-user-nickname {
        margin-top: 0.3rem;
        font-size: 0.7rem;
        color: $amber-700;
        // color: rgba($color: $gray-100, $alpha: 0.8);
        font-weight: bold;
    }

    .drawer-desc {
        margin-top: 0.7rem;
        font-size: 0.8rem;
        color: $gray-600;
        font-weight: 600;
    }

    .bottom-area {
        display: flex;
        align-items: center;
        margin-top: auto;
    }

    .star-area,
    .fork-area {
        display: flex;
        align-items: center;

        p {
            font-size: 0.7rem;
            color: $gray-500;
            font-weight: 600;
        }

        .star-icon {
            color: $amber-400;
            margin-right: 5px;
        }

        .fork-icon {
            color: $gray-400;
            font-size: 1.3rem;
            margin-right: 5px;
        }
    }

    .star-area {
        margin-right: 1rem;
    }
}
