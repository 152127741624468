@import "styles";

.how-to-use {
    width: 70%;

    @include mobile {
        width: 100%;
    }

    h2 {
        margin-top: 2rem;
        font-size: 1.5rem;
        color: $gray-900;
    }

    h3 {
        margin-top: 1.5rem;
        font-size: 1.3rem;
        color: $amber-800;
    }

    p {
        word-break: keep-all;
        margin-top: 0.5rem;
        color: $gray-800;
        line-height: 1.5rem;
    }
    img {
        margin-top: 1rem;
        width: 60%;

        @include mobile {
            width: 90%;
        }

        &.card-component-img {
            width: 40%;

            @include mobile {
                width: 80%;
            }
        }
    }
}
