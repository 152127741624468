@import "styles";

.side-bar-link {
    display: flex;
    align-items: center;
    padding: 0.7rem 2rem;
    border-radius: 0.8rem;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: $gray-100;

        h3,
        p {
            color: $gray-800;
        }
    }

    &.active {
        position: relative;
        background-color: $gray-100;

        [class*="-icon"] {
            color: $amber-800;
        }

        h3,
        p {
            color: $gray-800;
        }
    }

    [class*="-icon"] {
        margin-right: 0.5rem;

        @include mobile {
            font-size: x-large;
        }
    }

    h3 {
        font-size: 0.9rem;
        font-weight: bold;
        color: rgba($color: $gray-800, $alpha: 0.7);

        @include mobile {
            font-size: 1.2rem;
        }
    }

    p {
        font-size: 0.9rem;
        color: rgba($color: $gray-800, $alpha: 0.7);

        @include mobile {
            font-size: 1.2rem;
        }
    }
}
