@import "styles";

.search-page {
    padding-top: 3rem;

    @include mobile {
        padding-top: 0;
    }

    .search-input-area {
        display: flex;
        align-items: center;
        margin-bottom: 3rem;

        @include mobile {
            width: 100%;
        }

        input {
            width: 30rem;
            @include border-input;

            @include mobile {
                width: 100%;
            }
        }

        button {
            margin-left: 10px;
            @include border-btn;
        }
    }

    .searched-list-area {
        margin-bottom: 1rem;

        h2 {
            margin-bottom: 1rem;
            color: $gray-300;
            font-weight: 600;
            font-size: 0.9rem;
        }

        ul {
            display: flex;

            @include mobile {
                flex-direction: column;
            }
        }
    }
}
