@import "styles";

.side-bar {
    $side-bar-right-padding: 2.5rem;
    background-color: $gray-50;
    width: 20rem;
    height: 100%;
    position: relative;
    overflow-y: auto;
    border-right: 2px solid rgba($color: $gray-900, $alpha: 0.1);
    padding-top: 2.1rem;

    @include mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 18rem;
        transform: translateX(-100%);
        z-index: 100;
        transition: transform 0.5s;
        height: 100%;
        box-sizing: border-box;
        overflow-y: initial;

        &.show-mobile-side-menu {
            transform: translateX(0);
        }
    }

    .mobile-side-menu-btn {
        display: none;
        position: absolute;
        top: 0.5rem;
        right: 0;
        transform: translateX(130%);
        border: 3px solid $amber-700;
        background-color: rgba($color: $gray-50, $alpha: 0.7);
        color: $amber-700;
        border-radius: 0.7rem;

        @include mobile {
            display: inline-block;
        }
    }

    h2 {
        font-size: 0.9rem;
        color: $gray-700;
        font-weight: 700;
        padding: 1rem $side-bar-right-padding 0.5rem;

        @include mobile {
            font-size: 0.8rem;
        }
    }

    .main-box-area {
        margin: 0.7rem;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $gray-300;
        padding-bottom: 0.7rem;
    }

    .add-drawer-icon {
        font-size: 1.4rem;
    }

    .user-side-bar {
        margin: 0 0.7rem;
    }

    .non-login {
        padding: 0 0.7rem;
    }
}
