@import "styles";

.card-component {
    margin: 1rem;
    display: inline-block;
    position: relative;
    width: 25rem;
    height: 13rem;
    border-top: 1px solid $amber-800;
    overflow: hidden;
    cursor: pointer;

    @include mobile {
        width: 100%;
        height: 12rem;
        margin: 0.3rem 0;
    }

    .text-contents {
        padding: 0.7rem 0.9rem;
        background-color: transparent;
        height: 100%;
    }

    .card-component-title {
        display: flex;
        align-items: center;
        color: $amber-800;
        margin-bottom: 7px;
        font-weight: 500;
        font-size: 1.1rem;

        @include mobile {
            font-size: 1.1rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .card-component-desc {
        font-size: 0.9rem;
        color: $gray-700;

        @include mobile {
            font-size: 0.8rem;
        }
    }

    .status-list {
        margin-top: 1rem;

        li {
            display: flex;
            align-items: center;
            font-size: 0.9rem;

            h4 {
                color: $gray-600;
                font-weight: 500;
                font-size: 0.9rem;
                margin-right: 0.5rem;
            }

            [class*="-tag"] {
                color: $gray-800;
                border-radius: 5px;
                padding: 1px 3px;
                font-weight: 700;
            }

            .site-url-tag {
                margin-right: 5px;
                width: 80%;
                color: $blue-700;
                text-decoration: underline;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @include mobile {
                    width: 15rem;
                }
            }

            .link-tag {
                background-color: $deep-purple-300;
                display: flex;
                align-items: center;

                .launch-icon {
                    font-size: medium;
                    margin-right: 3px;
                }
            }
        }
    }

    .owner-menu-btn {
        position: absolute;
        bottom: 0.3rem;
        padding: 0.5rem 1.2rem;
        border: none;
        border-radius: 0.3rem;
        background-color: transparent;
        color: $deep-purple-300;
        font-weight: 500;

        &:hover {
            color: $deep-purple-600;
            text-decoration: underline;
        }
    }

    .add-today-btn {
        left: 0;
    }

    .edit-btn {
        right: 0;
    }

    // .card-component-url {
    //     position: absolute;
    //     z-index: 10;
    //     bottom: 10px;
    //     left: 10px;
    //     font-size: 0.7rem;
    //     color: $gray-400;

    //     @include mobile {
    //         font-size: 0.5rem;
    //     }
    // }
}
