.privacy-policy {
    h2 {
        margin-bottom: 15px;
        text-align: center;
    }

    p + p {
        margin-top: 10px;
    }
}
