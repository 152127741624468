@import "styles";

.sign-up-page {
    width: 30rem;

    @include mobile {
        width: 100%;
    }

    h1 {
        font-size: 2rem;
        color: $gray-800;
        margin-bottom: 1rem;

        @include mobile {
            margin-bottom: 1.2rem;
        }
    }

    form {
        > div:not(.sign-up-btn-area) {
            display: flex;
            flex-direction: column;
        }

        > div + div {
            margin-top: 1rem;
        }

        label {
            color: $gray-700;
            font-size: 0.8rem;
            font-weight: 700;
            margin-bottom: 10px;

            @include mobile {
                font-size: 1rem;
            }
        }

        input:not([type="checkbox"]) {
            @include border-input;
            width: 30rem;

            @include mobile {
                width: 100%;
            }
        }

        input + input {
            margin-top: 10px;
        }
    }

    .desc {
        color: $amber-600;
        font-weight: bold;
        font-size: 0.8rem;
        margin-top: 5px;
    }

    .email-input-area {
        display: flex;
        align-items: center;
        width: 30rem;

        @include mobile {
            width: 100%;
        }

        input:not([type="checkbox"]) {
            width: 24rem;
        }
    }

    .verification-code-area {
        p {
            margin-top: 5px;
        }
    }

    .show-pwd-area {
        margin-top: 5px;
        display: flex;
        align-items: center;

        label {
            margin-bottom: 0;
            margin-left: 10px;
        }
    }

    .verify-btn {
        margin-left: 10px;
        @include border-btn;
    }

    .sign-up-btn-area {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}
