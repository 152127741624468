$gray-900: #212121;
$gray-800: #424242;
$gray-700: #616161;
$gray-600: #757575;
$gray-500: #9e9e9e;
$gray-400: #bdbdbd;
$gray-300: #e0e0e0;
$gray-200: #eeeeee;
$gray-100: #f5f5f5;
$gray-50: #fafafa;

$red-500: #f44336;
$red-600: #e53935;

$deep-purple-900: #311b92;
$deep-purple-800: #4527a0;
$deep-purple-700: #512da8;
$deep-purple-600: #5e35b1;
$deep-purple-500: #673ab7;
$deep-purple-400: #7e57c2;
$deep-purple-300: #9575cd;
$deep-purple-200: #b39ddb;
$deep-purple-100: #d1c4e9;

$amber-900: #ff6f00;
$amber-800: #ff8f00;
$amber-700: #ffa000;
$amber-600: #ffb300;
$amber-500: #ffc107;
$amber-400: #ffca28;
$amber-300: #ffd54f;
$amber-200: #ffe082;

$yellow-300: #fff176;
$yellow-500: #ffeb3b;

$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1e88e5;
