@import "styles";

.main-header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.6rem;
    width: 100%;
    height: $header-height;
    background-color: $gray-50;
    border-bottom: 2px solid rgba($color: $gray-900, $alpha: 0.1);

    @include mobile {
        padding: 1rem 1rem;
    }

    .logo {
        width: 8rem;
        height: auto;

        @include mobile {
            width: 7rem;
        }
    }

    .header-btn-area {
        display: flex;
    }

    .header-icon {
        margin-left: 0.5rem;
        color: $amber-400;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;

        &:hover {
            color: $amber-700;
        }
    }
}
