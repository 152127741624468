@import "styles";

.new-card {
    display: flex;
    flex-direction: column;
    width: 40%;
    min-width: 34rem;

    @include mobile {
        width: 100%;
        min-width: 100%;
    }

    h1 {
        font-size: 2rem;
        color: $gray-800;
        margin-bottom: 1rem;
    }

    .page-desc {
        font-size: 0.8rem;
        font-weight: bold;
        color: $gray-600;
        white-space: nowrap;

        @include mobile {
            font-size: 0.9rem;
            white-space: normal;
            line-height: 1.3;
        }
    }

    .page-desc + .page-desc {
        margin-top: 0.2rem;

        @include mobile {
            margin-top: 0;
        }
    }

    > div,
    form > div {
        margin-top: 1.5rem;
        display: inline-block;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    label {
        color: $gray-600;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 0.8rem;

        @include mobile {
            font-size: 1.1rem;
        }
    }

    input,
    textarea,
    .drawer-list {
        @include border-input;
    }

    input {
        height: 2.5rem;
        width: 100%;
        font-size: 1rem;
    }

    textarea {
        height: 5rem;
        font-size: 1rem;
        width: 100%;
    }

    .drawer-list {
        width: 50%;

        @include mobile {
            width: 70%;
        }
    }

    .desc-area {
        label {
            span {
                font-size: 0.7rem;
                color: $gray-600;
                margin-left: 10px;

                @include mobile {
                    font-size: 0.8rem;
                }
            }
        }
    }

    .btn-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
