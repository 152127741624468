@import "styles";

* {
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
    margin: 0;
}

button {
    cursor: pointer;

    &:focus {
        outline: 0;
    }
}

textarea {
    resize: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px $gray-50 inset !important;
    -webkit-text-fill-color: $gray-700 !important;
    border-color: rgba($color: $gray-900, $alpha: 0.1) !important;
}

#root {
    height: 100%;
}

.App {
    display: flex;
    flex-direction: row;
    padding-top: $header-height;
    height: 100%;

    @include mobile {
        padding-bottom: $mobile-navi-height;
    }
}

.main-contents {
    width: 100%;
    overflow-y: auto;
    background-color: $gray-50;

    .main-contents-body {
        padding: 1rem;
    }
}

.err-msg {
    margin-top: 5px;
    color: $red-500;
    font-weight: bold;
    font-size: 0.8rem;

    @include mobile {
        font-size: 0.9rem;
    }
}

.corner-btn-area {
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
    color: $gray-600;

    .corner-btn {
        border-radius: 40%;

        &:hover {
            color: $amber-700;
        }

        @include mobile {
            font-size: large;
        }
    }

    .corner-btn + .corner-btn {
        margin-left: 5px;
    }
}
