@import "styles";

.drawer-page {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    .drawer-contents {
        flex: 1;
    }

    h2 {
        font-size: 1.1rem;
        color: $gray-700;
        margin-left: 0.4rem;

        @include mobile {
            font-size: 1.2rem;
        }
    }

    .top-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include mobile {
        }
    }

    .title-area {
        margin-bottom: 1rem;
    }

    .action-area {
        margin-right: 1rem;

        @include mobile {
            margin-right: 0;
            display: flex;
            flex-direction: column;
        }

        .edit-drawer-btn,
        .remove-drawer-btn {
            font-size: 0.9rem;
            width: 4.5rem;
            height: 2rem;
            border: 0;
            border-radius: 5px;
            background-color: $gray-300;
            color: $gray-700;

            &:hover {
                color: $gray-900;
                background-color: $gray-400;
            }

            @include mobile {
                width: 4rem;
            }
        }

        .remove-drawer-btn {
            margin-left: 0.5rem;

            @include mobile {
                margin-left: 0;
                margin-top: 0.2rem;
            }
        }

        @include mobile {
            // flex-direction: column;
            // align-items: center;
        }
    }

    .title {
        display: flex;
        align-items: center;

        .deleted-drawer-status {
            font-size: 0.8rem;
            margin-left: 0.4rem;
            color: $red-500;
        }
    }

    .desc {
        margin-top: 0.7rem;
        font-size: 1rem;
        font-weight: bold;
        color: rgba($color: $gray-600, $alpha: 0.7);

        @include mobile {
            font-size: 1rem;
        }
    }

    .tag-list {
        display: flex;
        margin-top: 1rem;

        li {
            font-size: 0.9rem;
            background-color: $deep-purple-300;
            color: $gray-50;
            padding: 2px 8px;
            border-radius: 4px;
            font-weight: 500;

            @include mobile {
                font-size: 0.9rem;
            }
        }

        li + li {
            margin-left: 5px;
        }
    }
}
