@import "styles";

.add-card-btn {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid $amber-700;
    background-color: $gray-50;

    color: $amber-800;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        right: 1rem;
        width: 2.8rem;
        height: 2.8rem;
    }

    &:hover {
        color: white;
        background-color: $amber-800;
    }
}
