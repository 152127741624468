@import "styles";

.submit-btn-component {
    width: 10rem;
    height: 3rem;
    background-color: $amber-800;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 0.5rem;

    @include mobile {
        width: 12rem;
        font-size: 1.2rem;
    }

    &:disabled {
        background-color: $gray-400;
        cursor: not-allowed;
    }
}
