@import "styles";

.login-page {
    display: flex;
    flex-direction: column;
    width: 30rem;

    @include mobile {
        width: 100%;
    }

    h1 {
        font-size: 2rem;
        color: $gray-800;
        margin-bottom: 1rem;

        @include mobile {
            margin-bottom: 3rem;
        }
    }

    .id-field,
    .pwd-field {
        display: flex;
        flex-direction: column;

        label {
            color: $gray-700;
            font-size: 0.8rem;
            font-weight: 700;
            margin-bottom: 10px;

            @include mobile {
                font-size: 1rem;
            }
        }

        input {
            width: 100%;
            @include border-input;
        }
    }

    .pwd-field {
        margin-top: 1rem;
    }

    .error-text {
        font-size: 0.9rem;
        color: $red-500;
        margin-top: 5px;

        @include mobile {
            font-size: 1rem;
        }
    }

    .move-to-register-page,
    .move-to-policy-page {
        display: block;
        margin-top: 10px;
        font-weight: 500;
        font-size: 0.9rem;
        color: $gray-700;
        text-align: center;

        @include mobile {
            margin-top: 1rem;
            font-size: 1rem;
        }

        &:hover {
            color: $amber-800;
        }
    }

    .move-to-policy-page {
        margin-top: 2rem;
        color: $gray-500;
    }

    .login-btn-area {
        margin-top: 1rem;
        display: flex;
        justify-content: center;

        @include mobile {
            margin-top: 1.3rem;
        }
    }

    .MuiSnackbar-anchorOriginBottomCenter {
        bottom: 100px;

        @include mobile {
            bottom: 7rem;
        }
    }
}
