@mixin border-input {
    border: 2px solid rgba($color: $gray-900, $alpha: 0.1);
    border-radius: 0.3rem;
    color: $gray-700;
    background-color: transparent;
    font-size: 1.1rem;
    padding: 7px;

    &::placeholder {
        color: rgba($color: $gray-50, $alpha: 0.7);
        font-size: 0.9rem;
    }

    &:focus {
        border-color: $amber-800;
    }

    &.error {
        border-color: $red-500;
    }
}

@mixin border-btn {
    height: 39px;
    border: 3px solid $amber-800;
    background-color: transparent;
    color: $gray-800;
    border-radius: 4px;
    font-weight: bold;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    white-space: nowrap;

    &:hover {
        background-color: $amber-800;
        color: $gray-50;
    }

    &:disabled {
        background-color: $gray-500;
        border-color: $gray-500;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$breakpoint-mobile}) {
        @content;
    }
}
