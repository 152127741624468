@import "styles";

.mobile-navigation {
    display: none;

    @include mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: $mobile-navi-height;
        border-top: 1px solid $gray-300;
        padding: 0.3rem 0;
    }

    .bar-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        color: $gray-800;

        h2 {
            margin-top: 2px;
            font-size: 0.8rem;
        }

        &.active {
            color: $deep-purple-600;
        }
    }

    .add-item {
        justify-content: center;
    }

    .add-item-icon {
        font-size: 3.7rem;
    }
}
