@import "styles";

#best-main {
    padding: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;

    @include mobile {
        padding: 1rem 0;
    }
}
